import React, { useEffect, useState } from "react";
import { serverUrl } from "../config";
import Cron from "./triggers/Cron";
import RelayModuleSwitch from "./outputs/RelayModuleSwitch";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConditionCodeBlock from "./controls/ConditionCodeBlock";
import AirAndSoilSensorData from "./variables/AirAndSoilSensorData";

type StateType = {
  config: any;
  pickedItems: any[];
  label: string | null;
  isEnabled: boolean;
};

const OptionItem = (props: any) => {
  const parentType = props.parentType;
  const item = props.item;

  return (
    <div
      className="cursor-pointer bg-white p-2 mt-2 rounded-lg shadow-md"
      key={item.type}
      onClick={() => props.itemClicked(parentType, item.type)}
    >
      <span>+ {item.label}</span>
    </div>
  );
};

const OptionGroup = (props: any) => {
  const config = props.config;

  return (
    <div className="mt-4">
      <h4 className="font-bold">{config.label}</h4>
      <div className="">
        {config.items.map((item: any) => (
          <OptionItem
            key={item.type}
            item={item}
            parentType={config.type}
            itemClicked={props.itemClicked}
          />
        ))}
      </div>
    </div>
  );
};

const PickedItem = (props: any) => {
  const item = props.item;
  const index = props.index;
  let component;

  if (item.parentType === "triggers" && item.type === "triggers:crontab_0") {
    component = <Cron item={item} ref={item.ref} />;
  } else if (
    item.parentType === "outputs" &&
    item.type === "outputs:relay_module_switch_0"
  ) {
    component = <RelayModuleSwitch item={item} ref={item.ref} />;
  } else if (
    item.parentType === "controls" &&
    item.type === "controls:condition_code_block_0"
  ) {
    component = <ConditionCodeBlock item={item} ref={item.ref} />;
  } else if (
    item.parentType === "variables" &&
    item.type === "variables:air_and_soil_sensor_data_0"
  ) {
    component = <AirAndSoilSensorData item={item} ref={item.ref} />;
  } else {
    component = (
      <div>
        {item.parentType} / {item.type}
      </div>
    );
  }

  const style = { marginLeft: parseInt(index) * 100 };

  return (
    <div
      className="rounded-2xl border-slate-300 bg-white shadow-md p-4 mt-8 w-80"
      style={style}
    >
      {component}
    </div>
  );
};

const EditAutomation = (props: any) => {
  const { automationId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<StateType>({
    config: null,
    pickedItems: [],
    label: null,
    isEnabled: true,
  });

  const itemClicked = (parentType: string, itemType: string) => {
    const id = uuidv4();
    const ref = React.createRef();
    let pickedItems = state.pickedItems;

    // update nextId on previous element
    if (pickedItems.length > 0) {
      const lastItem = pickedItems[pickedItems.length - 1];
      lastItem.ref.current.setNextId(id);
    }

    // update state
    pickedItems.push({
      parentType,
      type: itemType,
      ref,
      id,
      config: state.config[parentType].items.find(
        (row: any) => row.type === itemType,
      ),
    });
    setState({ ...state, pickedItems });
  };

  useEffect(() => {
    if (state.config !== null) {
      return;
    }

    (async () => {
      let newState = state;

      // fetch basic options
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-user-id": "00000000-0000-0000-0000-000000000000",
        },
      };

      const res1 = await fetch(
        serverUrl("/api/v1/automations/available_setup"),
        requestOptions,
      );

      let config: any;

      if (res1.status === 200) {
        config = await res1.json();
        newState = { ...newState, config };
      } else {
        throw new Error("invalid status");
      }

      // fetch item if in edit mode
      if (automationId) {
        const res2 = await fetch(
          serverUrl(`/api/v1/automations/${automationId}`),
          requestOptions,
        );

        if (res2.status === 200) {
          const data = await res2.json();
          let pickedItems = data.instructions.map(
            (item: any, index: number) => {
              const parentType = item.type.split(":")[0];
              const nextItem = data.instructions[index + 1];
              const nextId = nextItem ? nextItem.id : null;

              return {
                id: item.id,
                type: item.type,
                parentType,
                ref: React.createRef(),
                config: config[parentType].items.find(
                  (configItem: any) => configItem.type === item.type,
                ),
                refInitState: {
                  nextId,
                  value: item.value,
                },
              };
            },
          );
          newState = {
            ...newState,
            label: data.label,
            pickedItems,
            isEnabled: data.is_enabled,
          };
        } else {
          throw new Error("invalid status");
        }
      }

      setState({ ...state, ...newState });
    })();
  }, [state, automationId]);

  const save = async () => {
    const values = state.pickedItems.map((element) =>
      element.ref.current.toParam(),
    );

    const requestOptions = {
      method: automationId ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        "x-user-id": "00000000-0000-0000-0000-000000000000",
      },
      body: JSON.stringify({
        label: state.label,
        instructions: values,
        is_enabled: automationId ? state.isEnabled : undefined,
      }),
    };

    let result;

    if (automationId) {
      result = await fetch(
        serverUrl(`/api/v1/automations/${automationId}`),
        requestOptions,
      );
    } else {
      result = await fetch(serverUrl("/api/v1/automations"), requestOptions);
    }

    if (result.status === 204) {
      navigate(`/automations`);
    } else if (result.status === 400) {
      parseErrorResponse(result);
    } else {
      throw new Error("invalid status");
    }
  };

  const parseErrorResponse = (res: Response) => {
    res.json().then((json: any) => {
      const instructionError = json.errors.instructions;

      if (instructionError) {
        alert(instructionError);
      }
    });
  };

  return (
    <div>
      <div className="pt-6 md:pt-12 container mx-auto px-4">
        {automationId ? (
          <div className="absolute -mt-10 lg:-ml-12 lg:mt-0 cursor-pointer">
            <Link to={"/automations"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>
            </Link>
          </div>
        ) : null}
        <h1 className="text-3xl font-extrabold tracking-tight leading-none lg:text-4xl text-slate-800">
          Automations &gt; Edit
        </h1>
      </div>

      <div className="w-full mt-6 h-[calc(100vh-175px)] bg-black grid grid-cols-5">
        <div className="bg-slate-300 col-span-1 h-full border-r-2 border-slate-400/30 px-2">
          {state.config !== null &&
            Object.keys(state.config).map((key) => (
              <OptionGroup
                key={key}
                config={state.config[key]}
                itemClicked={itemClicked}
              />
            ))}
          <div className="mt-4">
            <h4 className="font-bold">Label</h4>
            <div className="mt-2">
              <input
                placeholder="Label"
                onChange={(evt) =>
                  setState({ ...state, label: evt.target.value })
                }
                value={state.label || ""}
                className="border text-sm rounded-lg block w-full p-2.5 text-slate-900"
              ></input>
            </div>
          </div>
          {automationId ? (
            <div className="mt-4">
              <h4 className="font-bold">Enabled</h4>
              <div className="mt-2">
                <select
                  className="border text-sm rounded-lg block w-full p-2.5 text-slate-900"
                  defaultValue={state.isEnabled.toString()}
                  key={state.isEnabled.toString()}
                  onChange={(evt) =>
                    setState({
                      ...state,
                      isEnabled: evt.target.value === "true",
                    })
                  }
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
          ) : null}
          <div className="mt-8">
            <button
              className="border text-sm rounded-lg py-2 px-4 w-full text-slate-900 bg-white"
              onClick={save}
            >
              Save
            </button>
          </div>
        </div>
        <div className="bg-slate-300 col-span-4 min-w-full h-full pt-4 pl-4 pb-32 pr-32 overflow-auto">
          {state.pickedItems.map((item, index) => (
            <PickedItem key={item.type + index} item={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditAutomation;
