import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { serverUrl } from "../config";

type DeviceInitDataType = {
  shortId: string;
  deviceId: string;
  btName: string;
  btWfSsidId: string;
  btWfPassId: string;
  btTokenId: string;
  btServerId: string;
};

type StateType = {
  ssid: string | null;
  password: string | null;
};

export type WiFiDataType = {
  ssid: string;
  password: string;
};

let bluetoothDevice: BluetoothDevice;

const fetchDeviceData = async (
  deviceId: string,
): Promise<DeviceInitDataType> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-user-id": "00000000-0000-0000-0000-000000000000",
    },
  };

  const response = await fetch(
    serverUrl(`/api/v1/devices/${deviceId}/pairing_details`),
    requestOptions,
  );
  const json = await response.json();

  return {
    shortId: json["short_id"],
    deviceId: deviceId,
    btName: json["bt_name"],
    btWfSsidId: json["bt_wf_ssid_id"],
    btWfPassId: json["bt_wf_pass_id"],
    btTokenId: json["bt_token_id"],
    btServerId: json["bt_server_id"],
  };
};

const generateCredentials = async (deviceId: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-user-id": "00000000-0000-0000-0000-000000000000",
    },
    body: JSON.stringify({}),
  };

  const response = await fetch(
    serverUrl(`/api/v1/devices/${deviceId}/auth_token`),
    requestOptions,
  );
  const data = await response.json();

  return {
    token: data["auth_token"],
    serverHost: data["server_host"],
  };
};

const readCharacteristc = async (
  service: BluetoothRemoteGATTService,
  id: string,
) => {
  const decoder = new TextDecoder();

  const characteristic = await service?.getCharacteristic(id);
  const value = await characteristic.readValue();

  return decoder.decode(value);
};

const writeCharacteristc = async (
  service: BluetoothRemoteGATTService,
  id: string,
  value: string,
) => {
  const encoder = new TextEncoder();

  const characteristic = await service?.getCharacteristic(id);

  return await characteristic.writeValue(encoder.encode(value));
};

const writeDeviceData = async (wifiData: WiFiDataType, navigate: Function) => {
  const serviceId = "c94d2e6a-29ad-4311-b845-fd7075a67fcd";
  const deviceIdCharacteristic = "c7a56e88-c4be-4cf9-a692-ff087f3588a5";
  const filters = [{ namePrefix: "GardenSense" }, { namePrefix: "Garden" }];
  const optionalServices = [serviceId];
  let bluetoothDevice: BluetoothDevice;

  try {
    bluetoothDevice = await navigator.bluetooth.requestDevice({
      acceptAllDevices: false,
      filters,
      optionalServices,
    });
  } catch (e) {
    return navigate("/devices");
  }

  const server = await bluetoothDevice.gatt?.connect();
  const service = await server?.getPrimaryService(serviceId);

  if (server && service) {
    const deviceId = await readCharacteristc(service, deviceIdCharacteristic);
    const deviceInitData = await fetchDeviceData(deviceId);

    const { token, serverHost } = await generateCredentials(deviceId);
    await writeCharacteristc(service, deviceInitData.btWfSsidId, wifiData.ssid);
    await writeCharacteristc(
      service,
      deviceInitData.btWfPassId,
      wifiData.password,
    );
    await writeCharacteristc(service, deviceInitData.btTokenId, token);
    await writeCharacteristc(service, deviceInitData.btServerId, serverHost);
    await server.disconnect();
    return navigate("/devices");
  } else {
    alert("server or service not available");
    return navigate("/devices");
  }
};

function LoadCredentials() {
  const [state, setState] = useState<StateType>({ ssid: null, password: null });
  const navigate = useNavigate();

  const setSssid = (ssid: string) => {
    setState({ ...state, ssid });
  };

  const setPassword = (password: string) => {
    setState({ ...state, password });
  };

  const handleSubmit = async () => {
    if (state.ssid && state.password) {
      const wiFiData: WiFiDataType = {
        ssid: state.ssid,
        password: state.password,
      };
      await writeDeviceData(wiFiData, navigate);
    }
  };

  useEffect(() => {
    return () => {
      if (bluetoothDevice?.gatt?.connected) {
        bluetoothDevice.gatt.disconnect();
      }
    };
  }, []);

  return (
    <div
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-slate-800/80"
    >
      <div className="relative w-full max-w-2xl max-h-full mx-auto">
        <div className="relative rounded-lg shadow bg-slate-700">
          <div className="flex items-start justify-between p-4 border-b rounded-t border-slate-600">
            <h3 className="text-xl font-semibold text-white">
              Configure the Device
            </h3>
            <Link
              to="/devices"
              className="text-slate-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </Link>
          </div>
          <div className="p-6 space-y-6">
            <p className="text-base leading-relaxed text-slate-400">
              To setup a new device, you must provide the desired WiFi network's
              information
            </p>
            <div className="mb-6">
              <label
                htmlFor="ssid-input"
                className="block mb-2 text-sm font-medium text-white"
              >
                Network Name (SSID)
              </label>
              <input
                onChange={(e) => setSssid(e.target.value)}
                type="text"
                id="ssid-input"
                className="border text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 bg-slate-700 border-slate-600 placeholder-slate-400 text-white"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password-input"
                className="block mb-2 text-sm font-medium text-white"
              >
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                id="password-input"
                className="border text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 bg-slate-700 border-slate-600 placeholder-slate-400 text-white"
              />
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2 border-t rounded-b border-slate-600">
            <button
              onClick={handleSubmit}
              type="button"
              className="flex-1 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
            >
              Configure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadCredentials;
