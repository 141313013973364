function Home() {
  return (
    <div className="pt-6 md:pt-12 container mx-auto px-4">
      <h1 className="text-3xl font-extrabold tracking-tight leading-none lg:text-4xl text-slate-800">
        Home
      </h1>
    </div>
  );
}

export default Home;
