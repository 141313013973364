import { Link, Route, Routes, useNavigate } from "react-router-dom";
import EditAutomation from "./automations/Edit";
import { useEffect, useState } from "react";
import { serverUrl } from "./config";
import automationIcon from "./automations/images/configuration_cog.png";

type ItemType = { id: string; label: string; is_enabled: boolean };
type StateType = { items: ItemType[] | null };

const Home = (props: any) => {
  const navigate = useNavigate();
  const [state, setState] = useState<StateType>({ items: null });

  useEffect(() => {
    if (state.items !== null) {
      return;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-user-id": "00000000-0000-0000-0000-000000000000",
      },
    };

    fetch(serverUrl("/api/v1/automations"), requestOptions)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }

        throw new Error("invalid status");
      })
      .then((data) => {
        setState({ ...state, items: data });
      })
      .catch((error) => {
        // handle
      });
  }, [state]);

  const openAutomation = (automationId: string) => {
    navigate(`/automations/${automationId}`);
  };

  return (
    <div className="pt-6 md:pt-12 container mx-auto px-4">
      <h1 className="text-3xl font-extrabold tracking-tight leading-none lg:text-4xl text-slate-800">
        Automations
      </h1>
      <div className="mt-12">
        {state.items?.map((item) => {
          const statusColor = item.is_enabled
            ? "bg-green-500"
            : "bg-yellow-500";

          return (
            <div
              className="w-full max-w-full lg:flex mt-8 cursor-pointer"
              key={item.id}
            >
              <div
                className="w-full rounded-2xl px-8 py-6 border-slate-300 bg-white relative flex place-content-between shadow-md items-center"
                onClick={() => openAutomation(item.id)}
              >
                <div className="flex items-center">
                  <img src={automationIcon} className="w-7 h-7" alt="Icon" />
                  <div className="text-slate-800 ml-4">
                    <span className="font-medium text-xl">{item.label}</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="relative">
                    <span
                      className={`block h-4 w-4 rounded-full ${statusColor}`}
                    ></span>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 lg:ml-6 ml-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="fixed right-6 bottom-24 group">
        <Link
          to="/automations/new"
          className="flex items-center justify-center text-white rounded-full w-14 h-14 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-800"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8 transition-transform"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
          <span className="sr-only">Open actions menu</span>
        </Link>
      </div>
    </div>
  );
};

const Automations = (props: any) => {
  return (
    <Routes>
      <Route path="/new" element={<EditAutomation />}></Route>
      <Route path="/:automationId" element={<EditAutomation />}></Route>
      <Route path="/" element={<Home />}></Route>
    </Routes>
  );
};

export default Automations;
