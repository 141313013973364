import { useState } from "react";
import { DeviceCapturesType, DeviceData } from "../Device";

const checkTruthy = (value: any) => value === "true" || value === true;

const DeviceActions = (props: any) => {
  const deviceData: DeviceData = props.deviceData;
  const actionsConfig = deviceData?.config?.actions;

  const initialState = Object.keys(actionsConfig).reduce(
    (acc: any, val: string) => {
      acc[val] = false;
      return acc;
    },
    {},
  );
  const [state, setState] = useState<any>(initialState);

  return (
    <div className="mt-6 bg-white px-5 py-5 pt-7.5 border shadow-sm rounded-lg">
      <div className="border-b border-stroke py-4 px-6.5">
        <h3 className="font-medium text-slate-800">Send Actions</h3>
      </div>
      {Object.keys(actionsConfig).map((key: string) => {
        const config = actionsConfig[key];
        const currentValue =
          deviceData?.captures[key as keyof DeviceCapturesType];
        const boolCurrentValue = checkTruthy(currentValue);

        return (
          <div key={key} className="flex flex-col gap-5.5 pt-6 px-6">
            <div>
              <label className="mb-3 block text-sm font-medium text-slate-800">
                {config.label}
              </label>
              <select
                onChange={(e) => setState({ ...state, [key]: e.target.value })}
                defaultValue={state[key]}
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value="true" selected={boolCurrentValue}>
                  On
                </option>
                <option value="false" selected={!boolCurrentValue}>
                  Off
                </option>
              </select>
            </div>
          </div>
        );
      })}
      <div className="pt-3 px-6 pb-12">
        <button
          onClick={() => {
            props.persistActions(state);
          }}
          className="btn bg-gray-200 text-gray-700 rounded py-1 px-3 mt-4"
        >
          Send!
        </button>
      </div>
    </div>
  );
};

export default DeviceActions;
