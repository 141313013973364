import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { serverUrl } from "./config";

import relayModuleIcon from "./devices/images/relay_module_icon.png";
import airAndSoildSensorIcon from "./devices/images/air_and_soil_sensor_icon.png";
import cameraModuleIcon from "./devices/images/camera_module_icon.png";

type DeviceType = {
  id: string;
  bt_name: string;
  display_name: string;
  config: {
    id: string;
    name: string;
    description: string;
  };
};

type StateType = { deviceList: DeviceType[] | null };

const DeviceIcon = (params: any) => {
  let url = "";

  if (params.config_id === "relay_module_v1") {
    url = relayModuleIcon;
  } else if (params.config_id === "air_and_soil_sensor_v1") {
    url = airAndSoildSensorIcon;
  } else if (params.config_id === "camera_module_v1") {
    url = cameraModuleIcon;
  }

  return <img src={url} className="w-7 h-7" alt="Icon" />;
};

function Devices() {
  const navigate = useNavigate();
  const [state, setState] = useState<StateType>({ deviceList: null });

  useEffect(() => {
    if (state.deviceList !== null) {
      return;
    }

    const saveDeviceList = (list: any[]) => {
      setState({ ...state, deviceList: list });
    };

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-user-id": "00000000-0000-0000-0000-000000000000",
      },
    };

    fetch(serverUrl("/api/v1/devices"), requestOptions)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }

        throw new Error("invalid status");
      })
      .then((data) => {
        saveDeviceList(data);
      })
      .catch((error) => {
        // handle
      });
  }, [state]);

  const openDevice = (deviceId: string) => {
    navigate(`/devices/${deviceId}`);
  };

  return (
    <div className="pt-6 md:pt-12 container mx-auto px-4">
      <h1 className="text-3xl font-extrabold tracking-tight leading-none lg:text-4xl text-slate-800">
        Devices
      </h1>

      <div className="device-list mt-12">
        {state.deviceList?.map((device) => {
          return (
            <div
              className="w-full max-w-full lg:flex mt-8 cursor-pointer"
              key={device.id}
            >
              <div
                className="w-full rounded-2xl px-8 py-6 border-slate-300 bg-white relative flex place-content-between shadow-md items-center"
                onClick={() => openDevice(device.id)}
              >
                <div className="flex items-center">
                  <div>
                    <DeviceIcon config_id={device.config.id} />
                  </div>
                  <div className="text-slate-800 ml-4">
                    <span className="font-medium text-xl">
                      {device.display_name || device.bt_name}
                    </span>
                    <span className="text-xs pl-2">{device.config.name}</span>
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="fixed right-6 bottom-24 group">
        <Link
          to="/devices/new"
          className="flex items-center justify-center text-white rounded-full w-14 h-14 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-800"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8 transition-transform"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
          <span className="sr-only">Open actions menu</span>
        </Link>
      </div>
    </div>
  );
}

export default Devices;
