import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { serverUrl } from "./config";
import { Link, useParams } from "react-router-dom";
import DeviceCaptures from "./device/DeviceCaptures";
import DeviceActions from "./device/DeviceActions";
import DeviceInfo from "./device/DeviceInfo";

type SoilSensorDeviceCapture = {
  humi: any[];
  mois: any[];
  temp: any[];
};

type ChannelType = {
  value: boolean;
};

type RelayDeviceCapture = {
  ch1: ChannelType;
  ch2: ChannelType;
};

export type DeviceCapturesType = SoilSensorDeviceCapture | RelayDeviceCapture;

export type DeviceData = {
  captures: DeviceCapturesType;
  bt_name: string;
  display_name: string;
  config: {
    id: string;
    name: string;
    capturesPersistence: string;
    description: string;
    captures: any;
    actions: any;
  };
};

type StateType = {
  deviceId: string;
  deviceData: DeviceData | null;
  seed: string;
  lastFetch: Date | null;
};

type DevicePropsType = {
  socket: any;
};

const fetchDeviceCaptures = async (deviceId: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-user-id": "00000000-0000-0000-0000-000000000000",
    },
  };

  return fetch(
    serverUrl(`/api/v1/devices/${deviceId}/captures`),
    requestOptions,
  ).then((res) => {
    if (res.status === 200) {
      return res.json();
    }

    throw new Error("invalid status");
  });
};

const deepMerge = (obj1: any, obj2: any) => {
  for (let key in obj1) {
    if (obj2.hasOwnProperty(key) && obj1[key] instanceof Array) {
      obj1[key].pop();
      obj1[key] = obj1[key].concat(obj2[key]);
    }
  }

  return obj1;
};

const Device = ({ socket }: DevicePropsType) => {
  const { deviceId } = useParams();
  const [state, setState] = useState<StateType>({
    deviceId: deviceId || "",
    deviceData: null,
    seed: uuidv4(),
    lastFetch: null,
  });

  useEffect(() => {
    if (!deviceId || !socket) {
      return;
    }

    const doFetch = () => {
      fetchDeviceCaptures(deviceId).then((deviceData) => {
        setState((state) => ({ ...state, deviceData, lastFetch: new Date() }));
      });
    };

    const interval = setInterval(doFetch, 5 * 60 * 1000);
    doFetch();

    const socketListener = (socketData: any) => {
      if (!socketData || socketData.deviceId !== deviceId) {
        return;
      }

      setState((state) => {
        const deviceData = state.deviceData;

        if (!deviceData || socketData.tags.from !== "module") {
          return state;
        }

        if (deviceData.config.id === "relay_module_v1") {
          deviceData.captures = socketData.data;
          return { ...state, deviceData };
        } else if (deviceData.config.id === "air_and_soil_sensor_v1") {
          deviceData.captures = deepMerge(deviceData.captures, socketData.data);
          return { ...state, deviceData };
        } else {
          return state;
        }
      });
    };

    socket.on(`/data_collected`, socketListener);

    return () => {
      clearInterval(interval);

      if (socket) {
        socket.off("/data_collected", socketListener);
      }
    };
  }, [deviceId, socket, state.seed]);

  const persistActions = (data: any) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-user-id": "00000000-0000-0000-0000-000000000000",
      },
      body: JSON.stringify(data),
    };

    fetch(
      serverUrl(`/api/v1/devices/${state.deviceId}/actions`),
      requestOptions,
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }

        throw new Error("invalid status");
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        // handle
      });
  };

  const updateDeviceInfo = (data: any) => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-user-id": "00000000-0000-0000-0000-000000000000",
      },
      body: JSON.stringify(data),
    };

    fetch(
      serverUrl(`/api/v1/devices/${state.deviceId}/public_info/change`),
      requestOptions,
    )
      .then((res) => {
        if (res.status === 204) {
          setState({ ...state, seed: uuidv4() });
        } else {
          throw new Error("invalid status");
        }
      })
      .catch((error) => {
        // handle
      });
  };

  return (
    <div
      className="pt-12 pb-36 container mx-auto px-4 relative"
      data-hash={state.seed}
    >
      <div className="absolute -mt-10 lg:-ml-12 lg:mt-0 cursor-pointer">
        <Link to={"/devices"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
        </Link>
      </div>
      <h1 className="text-3xl font-extrabold tracking-tight leading-none lg:text-4xl text-slate-800">
        {state.deviceData?.display_name || state.deviceData?.bt_name}
      </h1>
      <h3 className="text-slate-700 mt-2 text-sm">
        {state.deviceData?.config?.description}
      </h3>
      {state.deviceData ? (
        <DeviceCaptures deviceData={state.deviceData} />
      ) : null}
      {Object.keys(state.deviceData?.config?.actions || {}).length > 0 ? (
        <DeviceActions
          deviceData={state.deviceData}
          persistActions={persistActions}
        />
      ) : null}
      {state.deviceData ? (
        <DeviceInfo
          deviceData={state.deviceData}
          updateDeviceInfo={updateDeviceInfo}
        />
      ) : null}
    </div>
  );
};

export default Device;
