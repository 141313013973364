import { useImperativeHandle, forwardRef, useState } from "react";

type StateType = {
  value: string | null;
  nextId: string | null;
};

const RenderInputByType = (props: any) => {
  const groupName = props.group;
  const item = props.item;
  const groupObj = item.config.options[groupName];

  if (groupObj.type === "text") {
    return (
      <input
        type="text"
        className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
        name={groupName}
        placeholder={groupObj.placeholder}
        onChange={props.valueChanged}
        value={props.value || ""}
      />
    );
  } else {
    return null;
  }
};

const Cron = forwardRef((props: any, ref: any) => {
  let initState: StateType;
  const item = props.item;

  if (item.refInitState) {
    initState = item.refInitState;
  } else {
    initState = { value: null, nextId: null };
  }

  const [state, setState] = useState<StateType>(initState);
  const toParam = () => ({
    id: item.id,
    type: item.type,
    value: state.value,
    nextId: state.nextId,
  });
  const setNextId = (nextId: string) => setState({ ...state, nextId });

  useImperativeHandle(ref, () => ({ toParam, setNextId }));

  const valueChanged = (e: any) => {
    setState({ ...state, value: e.target.value });
  };

  return (
    <div className="">
      <h4 className="font-bold">Cron</h4>
      <div className="mt-4">
        <label>ID</label>
        <input
          type="text"
          className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
          disabled={true}
          value={item.id}
        />
      </div>
      {item.config.options.options.map((group: any) => (
        <div key={group} className="mt-4">
          <label>{item.config.options[group].label}</label>
          <RenderInputByType
            group={group}
            item={item}
            value={state.value}
            valueChanged={valueChanged}
          />
        </div>
      ))}
      <div className="mt-4">
        <label>Next ID</label>
        <input
          type="text"
          className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
          disabled={true}
          value={state.nextId || ""}
        />
      </div>
    </div>
  );
});

export default Cron;
