import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DeviceCapturesType, DeviceData } from "../Device";

const checkTruthy = (value: any) => value === "true" || value === true;

const LineChart = (props: any) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  return (
    <div className="chart-container" style={{ height: "calc(100vh - 300px)" }}>
      <Line
        data={props.chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

const DeviceCaptures = (props: any) => {
  const deviceData: DeviceData = props.deviceData;
  const captures = deviceData.captures;
  const captureConfig = deviceData.config.captures;

  switch (deviceData.config.id) {
    case "air_and_soil_sensor_v1": {
      const chartDatas = Object.keys(captures).map((captureKey) => {
        const config = captureConfig[captureKey];
        const list = Array.from(captures[captureKey as keyof typeof captures]);
        const labels = list.map((capture: any) => capture.timestamp);

        return {
          key: captureKey,
          title: config.label,
          labels,
          datasets: [
            {
              data: list.map((capture: any) => capture.value),
              borderColor: config.color,
            },
          ],
        };
      });

      return (
        <div>
          {Array.from(chartDatas).map((chartData) => {
            return (
              <div
                className="mt-12 text-white w-full rounded-2xl px-8 py-6 border-slate-300 bg-white shadow-md"
                key={chartData.key}
              >
                <h3 className="text-lg text-slate-800 font-semibold">
                  {chartData.title}
                </h3>
                <div className="mt-4">
                  <LineChart
                    chartData={{
                      labels: chartData.labels,
                      datasets: chartData.datasets,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    case "relay_module_v1": {
      return (
        <div>
          <div className="mt-12 bg-white col-span-12 rounded-lg border border-stroke shadow-default">
            <div className="grid grid-cols-1 md:grid-cols-2">
              {Object.keys(captures)
                .sort()
                .map((key: any) => {
                  const config = captureConfig[key];
                  const currentCapture: any =
                    captures[key as keyof DeviceCapturesType];
                  const active = checkTruthy(currentCapture.value);
                  const color = active ? "bg-green-500" : "bg-yellow-500";

                  return (
                    <div
                      className="flex text-center items-center justify-center border-b md:border-r border-stroke p-5"
                      key={key}
                    >
                      <div className="relative">
                        <h4 className="mb-0.5 text-xl font-bold text-slate-800 md:text-title-lg">
                          {active ? "On" : "Off"}
                        </h4>
                        <p className="text-sm font-medium">{config.label}</p>
                        <span
                          className={`mr-2 block h-6 w-6 left-0 inset-y-1/2 -ml-12 -mt-3 absolute rounded-full ${color}`}
                        ></span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default DeviceCaptures;
