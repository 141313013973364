import React from "react";
import { BrowserRouter, Route, Routes, NavLink } from "react-router-dom";
import { io } from "socket.io-client";
import Home from "./Home";
import Devices from "./Devices";
import Device from "./Device";
import { serverUrl } from "./config";
import Automations from "./Automations";
import LoadCredentials from "./devices/LoadCredentials";

export default class App extends React.Component<{}, { socket: any }> {
  constructor(props: any) {
    super(props);
    this.state = { socket: null };
  }

  classNameSetter(params: any) {
    const base =
      "inline-flex flex-col items-center justify-center px-5 hover:bg-slate-900 group";
    return params.isActive ? base + " bg-slate-900" : base;
  }

  componentDidMount(): void {
    const socket = io(serverUrl(""), {
      query: { userId: "00000000-0000-0000-0000-000000000000" },
    });
    this.setState({ socket });
  }

  render() {
    return (
      <div className="App bg-slate-200 w-full min-h-screen select-none">
        <div>
          <BrowserRouter>
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/devices/new"
                  element={<LoadCredentials />}
                ></Route>
                <Route
                  path="/devices/:deviceId"
                  element={<Device socket={this.state.socket} />}
                />
                <Route path="/devices/*" element={<Devices />} />
                <Route path="/automations/*" element={<Automations />} />
              </Routes>
            </div>
            <div className="fixed bottom-0 left-0 z-50 w-full h-16 border-t bg-slate-800 border-slate-900">
              <div className="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
                <NavLink to="/" className={this.classNameSetter}>
                  <svg
                    className="w-6 h-6 mb-1 text-slate-300 group-hover:text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span className="text-sm text-slate-300 group-hover:text-green-500">
                    Home
                  </span>
                </NavLink>
                <NavLink to="/devices" className={this.classNameSetter}>
                  <svg
                    className="w-6 h-6 mb-1 text-slate-300 group-hover:text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
                    />
                  </svg>
                  <span className="text-sm text-slate-300 group-hover:text-green-500">
                    Devices
                  </span>
                </NavLink>
                <NavLink to="/automations" className={this.classNameSetter}>
                  <svg
                    className="w-6 h-6 mb-1 text-slate-300 group-hover:text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                    />
                  </svg>
                  <span className="text-sm text-slate-300 group-hover:text-green-500">
                    Automations
                  </span>
                </NavLink>
              </div>
            </div>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}
