import { useImperativeHandle, forwardRef, useRef, useState } from "react";

type StateType = {
  nextId: string | null;
  value: any;
};

const RenderInputByType = (props: any) => {
  const groupName = props.group;
  const item = props.item;
  const groupObj = item.config.options[groupName];

  if (groupObj.type === "option") {
    return (
      <select
        className="mt-1 border text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 border-slate-600 placeholder-slate-400 text-slate-900"
        name={groupName}
        defaultValue={props.stateValue}
        onChange={(evt) => props.onValueChanged(groupName, evt.target.value)}
      >
        {groupObj.options.map((option: any) => (
          <option value={option.value} key={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    );
  } else {
    return null;
  }
};

const RelayModuleSwitch = forwardRef((props: any, ref: any) => {
  let initState: StateType;
  const item = props.item;

  if (item.refInitState) {
    initState = item.refInitState;
  } else {
    const defaultValue = item.config.options.options.reduce(
      (defaultValue: any, optionName: string) => {
        const optionItem = item.config.options[optionName];

        if (optionItem.type === "option") {
          defaultValue[optionName] =
            item.config.options[optionName].options[0].value;
        } else {
          defaultValue[optionName] = null;
        }

        return defaultValue;
      },
      {},
    );

    initState = { nextId: null, value: defaultValue };
  }

  const [state, setState] = useState<StateType>(initState);
  const formRef = useRef<HTMLFormElement>(null);

  const toParam = () => {
    if (formRef.current) {
      return {
        id: item.id,
        type: item.type,
        value: state.value,
        nextId: state.nextId,
      };
    } else {
      return { id: item.id, type: item.type, value: null, nextId: null };
    }
  };

  const setNextId = (nextId: string) => setState({ ...state, nextId });

  useImperativeHandle(ref, () => ({ toParam, setNextId }));

  const onValueChanged = (key: string, value: string) => {
    let finalValue: any = value;

    // override finalValue if given value is boolean
    if (value === "true" || value === "false") {
      finalValue = value === "true";
    }

    setState({ ...state, value: { ...state.value, [key]: finalValue } });
  };

  return (
    <div className="">
      <h4 className="font-bold">Relay Module</h4>
      <div className="mt-2 pb-2">
        <form ref={formRef}>
          <div className="mt-4">
            <label>ID</label>
            <input
              type="text"
              className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
              disabled={true}
              value={item.id}
            />
          </div>
          {item.config.options.options.map((group: any) => (
            <div key={group} className="mt-4">
              <label>{item.config.options[group].label}</label>
              <RenderInputByType
                group={group}
                item={item}
                stateValue={state.value[group]}
                onValueChanged={onValueChanged}
              />
            </div>
          ))}
          <div className="mt-4">
            <label>Next ID</label>
            <input
              type="text"
              className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
              disabled={true}
              value={state.nextId || ""}
            />
          </div>
        </form>
      </div>
    </div>
  );
});

export default RelayModuleSwitch;
