import { useState } from "react";
import { DeviceData } from "../Device";

const DeviceInfo = (props: any) => {
  const deviceData: DeviceData = props.deviceData;
  const initialDisplayName = deviceData.display_name;
  const [state, setState] = useState<any>({});

  return (
    <div className="mt-6 bg-white px-5 py-5 pt-7.5 border shadow-sm rounded-lg">
      <div className="border-b border-stroke py-4 px-6.5">
        <h3 className="font-medium text-slate-800">
          Change Device Configuration
        </h3>
      </div>
      <div className="pt-3 px-6 pb-12">
        <label className="mb-3 block text-sm font-medium text-slate-800">
          Display Name
        </label>
        <input
          type="text"
          onChange={(e) => setState({ display_name: e.target.value })}
          defaultValue={initialDisplayName}
          className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        ></input>
        <button
          onClick={() => {
            props.updateDeviceInfo(state);
          }}
          className="btn bg-gray-200 text-gray-700 rounded py-1 px-3 mt-4"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default DeviceInfo;
