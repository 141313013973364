import { useImperativeHandle, forwardRef, useState } from "react";

type StateType = {
  nextId: string | null;
  value: any;
};

const RenderInputByType = (props: any) => {
  const groupName = props.group;
  const item = props.item;
  const groupObj = item.config.options[groupName];

  if (groupObj.type === "textarea") {
    return (
      <textarea
        className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
        onKeyDown={props.valueChanged}
        onChange={(evt) => props.onValueChanged(groupName, evt.target.value)}
        value={props.stateValue || ""}
      />
    );
  } else {
    return null;
  }
};

const ConditionCodeBlock = forwardRef((props: any, ref: any) => {
  let initState: StateType;
  const item = props.item;

  if (item.refInitState) {
    initState = item.refInitState;
  } else {
    const defaultValue = item.config.options.options.reduce(
      (defaultValue: any, optionName: string) => {
        const optionItem = item.config.options[optionName];

        if (optionItem.type === "textarea") {
          defaultValue[optionName] =
            item.config.options[optionName].placeholder;
        } else {
          defaultValue[optionName] = null;
        }

        return defaultValue;
      },
      {},
    );

    initState = { nextId: null, value: defaultValue };
  }

  const [state, setState] = useState<StateType>(initState);
  const toParam = () => ({
    id: item.id,
    type: item.type,
    value: state.value,
    nextId: state.nextId,
  });
  const setNextId = (nextId: string) => setState({ ...state, nextId });

  useImperativeHandle(ref, () => ({ toParam, setNextId }));

  return (
    <div className="">
      <h4 className="font-bold">Condition Code Block</h4>
      <div className="mt-4">
        <label>ID</label>
        <input
          type="text"
          className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
          disabled={true}
          value={item.id}
        />
      </div>
      {item.config.options.options.map((group: any) => (
        <div key={group} className="mt-4">
          <label>{item.config.options[group].label}</label>
          <RenderInputByType
            group={group}
            item={item}
            stateValue={state.value[group]}
            onValueChanged={(key: string, value: string) =>
              setState({ ...state, value: { ...state.value, [key]: value } })
            }
          />
        </div>
      ))}
      <div className="mt-4">
        <label>Next ID (when True)</label>
        <input
          type="text"
          className="mt-1 border text-sm rounded-lg block w-full p-2.5 border-slate-600 text-slate-900 select-none"
          disabled={true}
          value={state.nextId || ""}
        />
      </div>
    </div>
  );
});

export default ConditionCodeBlock;
